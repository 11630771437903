// Generated by Framer (89324c0)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CA3nq_x99"];

const serializationHash = "framer-YSXfJ"

const variantClassNames = {CA3nq_x99: "framer-v-3ga7e7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CA3nq_x99", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YSXfJ", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-3ga7e7", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CA3nq_x99"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-pnllhi"} data-framer-name={"KRAUSS_Logo_Blau"} layout={"position"} layoutDependency={layoutDependency} layoutId={"Edil10TSt"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 73 76\"><path d=\"M 28.942 61.306 L 17.555 61.306 L 17.555 12.624 L 38.122 12.624 C 42.464 12.624 46.876 12.697 50.646 15.109 C 54.631 17.735 56.839 22.491 56.839 27.242 C 56.839 32.779 53.637 37.886 48.369 39.801 L 57.407 61.303 L 44.741 61.303 L 37.27 42.071 L 28.946 42.071 L 28.946 61.303 Z M 28.942 33.701 L 35.204 33.701 C 37.554 33.701 40.115 33.912 42.177 32.709 C 44.027 31.572 45.025 29.302 45.025 27.173 C 45.025 25.259 43.885 23.199 42.249 22.204 C 40.329 20.998 37.055 21.281 34.92 21.281 L 28.942 21.281 Z\" fill=\"rgb(0,157,239)\"></path><path d=\"M 0.203 37.731 C 0.203 16.927 16.467 0 36.459 0 C 56.451 0 72.714 16.927 72.714 37.731 C 72.714 58.535 56.451 75.461 36.459 75.461 C 16.467 75.461 0.203 58.535 0.203 37.731 Z M 5.145 37.734 C 5.145 55.822 19.194 70.537 36.459 70.537 C 53.727 70.537 67.772 55.822 67.772 37.734 C 67.772 19.646 53.723 4.931 36.459 4.931 C 19.19 4.931 5.145 19.646 5.145 37.734 Z\" fill=\"rgb(0,157,239)\"></path></svg>"} svgContentId={152099075} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YSXfJ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YSXfJ .framer-1x9bsnu { display: block; }", ".framer-YSXfJ .framer-3ga7e7 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-YSXfJ .framer-pnllhi { flex: none; height: 76px; position: relative; width: 73px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-YSXfJ .framer-3ga7e7 { gap: 0px; } .framer-YSXfJ .framer-3ga7e7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-YSXfJ .framer-3ga7e7 > :first-child { margin-left: 0px; } .framer-YSXfJ .framer-3ga7e7 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 76
 * @framerIntrinsicWidth 73
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 */
const Framerj50VolJm_: React.ComponentType<Props> = withCSS(Component, css, "framer-YSXfJ") as typeof Component;
export default Framerj50VolJm_;

Framerj50VolJm_.displayName = "LogoR";

Framerj50VolJm_.defaultProps = {height: 76, width: 73};

addFonts(Framerj50VolJm_, [])